/* eslint-disable */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */

// react imports
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// notistack imports
import { useSnackbar } from "notistack";

// @mui material components
// import FormControl from "@mui/material/FormControl";
import { Card, NativeSelect, Checkbox, Icon } from "@mui/material";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController, setCart, setEmptyCart } from "context";
 
function ShoppingCard(props) {
  const {
    id,
    study,
    storeIndex,
    image,
    download,
    title,
    dimensions,
    description,
    quantity,
    quantityOptions,
    languageVersion,
    customization,
    customizationOptions,
    customizationRequired,
    inactiveStudies,
    storeData,
    active,
    meta,
    listDescription,
    action,
  } = props;
  const [controller, dispatch] = useMaterialUIController();
  const { cart } = controller;

  const [value, setValue] = useState(0);
  const [language, setLanguage] = useState("English");
  const [readMore, setReadMore] = useState(false);
  const [willCustomize, setWillCustomize] = useState(customizationRequired);
  const [isChecked, setIsChecked] = useState(customizationRequired);
  const { enqueueSnackbar } = useSnackbar();

  const [imgSrc, setImgSrc] = useState(image);
  const [imgSrcKey, setImgSrcKey] = useState(0);
  useEffect(() => {
    const newImage = language && meta[language] && meta[language][0] && meta[language][0].image ? meta[language][0].image : image;
    // console.log('image', newImage)
    setImgSrc(newImage);
    setImgSrcKey(imgSrcKey + 1);
    return () => {};
  }, [language, image]);

  const listDescriptionMap = listDescription.map((el) => (
    <ul>
      <li>{el}</li>
      {["Brochure", "Poster"].some((x) => el.includes(x)) && (
        <MDTypography variant="caption" color="success">
          *Item can be customized
        </MDTypography>
      )}
    </ul>
  ));

  const addToCart = (el) => {
    setEmptyCart(dispatch, false);
    const variant = "success";
    if (cart.some((e) => e.newID === el.newID)) {
      const cartItemQuantity = cart.find((obj) => obj.newID === el.newID);
      const brandNewValue = cartItemQuantity.cartQuantity + el.cartQuantity;
      const updatedCartItem = cart.map((x) =>
        x.newID === el.newID ? { ...x, cartQuantity: brandNewValue } : x
      );
      // let hardCopy = [...cart];
      // hardCopy = hardCopy.filter((cartItem) => cartItem.newID !== el.newID);
      // setCart(dispatch, hardCopy);
      setCart(dispatch, updatedCartItem);
      // window.localStorage.setItem("cart", JSON.stringify(hardCopy));
      window.localStorage.setItem("cart", JSON.stringify(updatedCartItem));
      enqueueSnackbar(`${el.title} - ${el.language} added to cart!`, {
        variant,
        autoHideDuration: 2000,
      });
    } else {
      const findCartIndex = cart.find((obj) => obj.id === el.id);
      if (findCartIndex === undefined || null) {
        const addItem = [...cart, el];
        setCart(dispatch, [...cart, el]);
        window.localStorage.setItem("cart", JSON.stringify(addItem));
        enqueueSnackbar(`${el.title} - ${el.language} added to cart!`, {
          variant,
          autoHideDuration: 2000,
        });
      } else {
        const setCartIndex = cart.indexOf(findCartIndex);
        const newCart = cart.splice(setCartIndex, 0, el);
        window.localStorage.setItem("cart", JSON.stringify(newCart));
        enqueueSnackbar(`${el.title} - ${el.language} added to cart!`, {
          variant,
          autoHideDuration: 2000,
        });
      }
    }
  };

  return (
    <Card sx={{ opacity: active ? "1" : ".5" }}>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
        <MDBox
          key={imgSrcKey}
          component="img"
          src={imgSrc}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="3%"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox p={3}>
        <MDTypography display="inline" variant="h3" textTransform="capitalize" fontWeight="bold">
          {title}
        </MDTypography>
        <MDBox mt={2} mb={3}>
          <MDTypography variant="body2" component="p" color="text">
            <strong>Dimensions: </strong>
            {dimensions}
          </MDTypography>
          <MDTypography variant="body2" component="p" color="text">
            <strong>Description: </strong>
            <br />
            {readMore ? description : `${description.slice(0, 100)}...`}
            {listDescription.length !== 0 &&
              (readMore ? listDescriptionMap : listDescriptionMap.slice(0, 2))}
          </MDTypography>
          <MDButton
            component="button"
            onClick={() => {
              setReadMore(!readMore);
            }}
            variant="text"
            color="info"
            sx={{ display: "block" }}
          >
            {readMore ? "see less" : "read more"}
          </MDButton>
          {active ? (
            <>
              {action.type === "external" ? (
                <MuiLink href={action.route} target="_blank" rel="noreferrer">
                  <MDButton color={action.color ? action.color : "dark"}>{action.label}</MDButton>
                </MuiLink>
              ) : (
                <>
                  {
                    (!download) && (
                      <>
                        <MDTypography
                          variant="body2"
                          component="p"
                          color="text"
                          sx={{ display: "inline" }}
                        >
                          {quantity}
                        </MDTypography>
                        <NativeSelect
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          defaultValue={quantityOptions[0]}
                          inputProps={{
                            name: "quantity",
                            id: "uncontrolled-native",
                          }}
                        >
                          {quantityOptions.map((x) => (
                            <option value={x}>{x}</option>
                          ))}
                        </NativeSelect>
                      </>
                    )
                  }
                  <br />
                  <MDTypography
                    variant="body2"
                    component="p"
                    color="text"
                    sx={{ display: "inline" }}
                  >
                    Language:{" "}
                  </MDTypography>
                  <NativeSelect
                    onChange={(e) => {
                      setLanguage(e.target.value);
                    }}
                    defaultValue={languageVersion[0]}
                    inputProps={{
                      name: "language",
                      id: "uncontrolled-native",
                    }}
                  >
                    {languageVersion.map((x) => (
                      <option value={x}>{x}</option>
                    ))}
                  </NativeSelect>
                  <br />
                  {customization && (
                    <MDTypography variant="body2" component="p" color="text">
                      <Checkbox
                        onClick={() => {
                          setWillCustomize(!willCustomize);
                          setIsChecked(!isChecked);
                        }}
                        value={willCustomize}
                        checked={isChecked}
                        sx={{ opacity: customizationRequired ? 0.5 : 1 }}
                        disabled={customizationRequired}
                      />{" "}
                      Add Customization
                    </MDTypography>
                  )}
                  <MDBox mt={2} mb={3} display="flex" alignItems="center">
                    {
                      (!download) && (
                        <MDButton
                          sx={{ marginRight: "1rem", marginBottom: "1rem" }}
                          color={action.color ? action.color : "dark"}
                          onClick={() => {
                            let cartQuantity = value * 1;
                            if (value === 0) {
                              cartQuantity = quantityOptions[0] * 1;
                            }
                            let newID = `${id} - ${language}`;
                            addToCart({
                              id,
                              newID,
                              study,
                              storeIndex,
                              cartQuantity,
                              title,
                              image,
                              quantityOptions,
                              language,
                              languageVersion,
                              customization,
                              customizationOptions,
                              customizationRequired,
                              willCustomize,
                              storeData,
                              meta,
                              inactiveStudies,
                            });
                          }}
                        >
                          add to cart
                        </MDButton>
                      )
                    }
                    {
                      (download) && (
                        <MDButton
                          sx={{ marginRight: "1rem", marginBottom: "1rem" }}
                          color={action.color ? action.color : "dark"}
                          onClick={() => {
                            window.open(meta[language][0].download || download, "_blank").focus();
                          }}
                        >
                          digital download
                        </MDButton>
                      )
                    }
                  </MDBox>
                </>
              )}
            </>
          ) : (
            <MDBox bgColor="light" borderRadius={5} p={1}>
              <MDTypography variant="h3" align="center" color="secondary" my={3}>
                <Icon sx={{ mr: 1 }}>remove_shopping_cart_icon</Icon>
                Currently Out of Stock
              </MDTypography>
              <MDTypography variant="body2" align="center" color="text">
                If you have an urgent need for this item, please contact us through our contact form
                page.
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

ShoppingCard.defaultProps = {
  listDescription: [],
};

// Typechecking props
ShoppingCard.propTypes = {
  id: PropTypes.string.isRequired,
  study: PropTypes.string.isRequired,
  storeIndex: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dimensions: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  quantityOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  languageVersion: PropTypes.arrayOf(PropTypes.string).isRequired,
  customization: PropTypes.bool.isRequired,
  customizationOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  customizationRequired: PropTypes.bool.isRequired,
  listDescription: PropTypes.arrayOf(PropTypes.string),
  storeData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShoppingCard;
