/* eslint-disable */

// react imports
import { useState, useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { List, Divider, Icon, CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "nav/Sidenav/SidenavCollapse";
// import SidenavStore from "nav/Sidenav/SidenavStore";

// Custom styles for the Sidenav
import SidenavRoot from "nav/Sidenav/SidenavRoot";
import sidenavLogoLabel from "nav/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setUserStores,
  setCurrentStore,
  setActiveNavLink,
  setPageState,
} from "context";

// Firebase imports
import { getDocs, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db, logout } from "../../Firebase";

function Sidenav({ color, brand, brandName, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  // add to below: sidenavColor
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    userStores,
    // activeNavLink,
    pageState,
  } = controller;

  const auth = getAuth();
  const userInfo = auth.currentUser;

  // pageState set
  useEffect(() => {
    setPageState(dispatch, userStores[0]);
  }, [userStores]);

  useEffect(() => {
    const DashArray = [...userStores, "Dashboard"];
    console.log(DashArray.includes(userStores[0]));
    console.log(pageState);
  }, [userStores]);

  const location = useLocation();
  // const collapseName = location.pathname.replace("/", "");

  const [storeNotLoaded, setStoreNotLoaded] = useState(true);
  const [inactiveStudies, setInactiveStudies] = useState([]);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  useEffect(() => {
    const getStoreDocs = async () => {
      const storeUser = userInfo.uid;
      const inactiveColRef = await getDocs(collection(db, "STUDY_INACTIVE"));
      const _inactiveStudies = inactiveColRef.docs.map((el) => el.id);
      setInactiveStudies(_inactiveStudies);
      const colRef = await getDocs(collection(db, "USERS", storeUser, "STUDIES"));
      const userStudies = colRef.docs.map((el) => el.id).filter(item => !_inactiveStudies.includes(item));
      setUserStores(dispatch, userStudies);
      setStoreNotLoaded(false);
    };
    getStoreDocs();
  }, []);

  useEffect(() => {
    setActiveNavLink(dispatch, userStores[0]);
  }, [userStores]);

  const renderUserStores = userStores.map((el) => {
    return (
      <>
        {!inactiveStudies.includes(el) && <>
          <SidenavCollapse
            name={el.replace(/_/g, " ")}
            icon="arrow_forward"
            active={pageState === el}
            color="white"
            onClick={() => {
              setCurrentStore(dispatch, el);
              setActiveNavLink(dispatch, el);
              closeSidenav();
              setPageState(dispatch, el);
            }}
          />
        </>}
      </>
    );
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          to="/"
          display="flex"
          alignItems="center"
          onClick={() => {
            setCurrentStore(dispatch, userStores[0]);
            setPageState(dispatch, userStores[0]);
          }}
          sx={{ cursor: "pointer" }}
        >
          {brand && <MDBox component="img" src={brand} alt="Brand" width="1.5rem" mx={1} />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>
        {storeNotLoaded && (
          <MDBox px={2}>
            <CircularProgress color="white" />
          </MDBox>
        )}
        {renderUserStores}
      </List>
      <List>
        <Divider light />
        <SidenavCollapse
          name="Checkout"
          icon={<Icon fontSize="small">shopping_cart_checkout</Icon>}
          active={["Checkout", "Shipping", "Review", "Customization"].includes(pageState)}
          onClick={() => {
            closeSidenav();
            setPageState(dispatch, "Checkout");
          }}
        />
        <SidenavCollapse
          name="Your Orders"
          icon={<Icon fontSize="small">card_travel</Icon>}
          active={pageState === "Orders"}
          onClick={() => {
            closeSidenav();
            setPageState(dispatch, "Orders");
          }}
        />
        <SidenavCollapse
          name="Address Book"
          icon={<Icon fontSize="small">book</Icon>}
          active={["AddressBook", "AddressPlus"].includes(pageState)}
          onClick={() => {
            closeSidenav();
            setPageState(dispatch, "AddressBook");
          }}
        />
        <SidenavCollapse
          name="Contact"
          icon={<Icon fontSize="small">contacts</Icon>}
          active={["Contact", "ContactSuccess"].includes(pageState)}
          onClick={() => {
            closeSidenav();
            setPageState(dispatch, "Contact");
          }}
        />
        <NavLink key="sign-out" to="/authentication/sign-in">
          <SidenavCollapse
            name="Sign Out"
            icon={<Icon fontSize="small">login</Icon>}
            onClick={() => {
              closeSidenav();
              setActiveNavLink(dispatch, "sign-in");
              logout();
            }}
          />
        </NavLink>
      </List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
};

export default Sidenav;
