/* eslint-disable */
/* eslint-disable no-unused-vars */

// react imports
import { useEffect, useState } from "react";

// Firebase imports
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../Firebase";

// notistack imports
import { useSnackbar } from "notistack";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Component Imports
import CartCard from "nav/Configurator/components/CartCard";

// Custom styles for the Configurator
import ConfiguratorRoot from "nav/Configurator/ConfiguratorRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setCart,
  setEmptyCart,
  setChosenAddress,
  setFinalAddress,
  setLocalCustomization,
  setPageState,
  setCartStudies,
} from "context";

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, darkMode, cart, emptyCart, editOrder, cartStudies } = controller;
  const { enqueueSnackbar } = useSnackbar();

  const [cartCustomizations, setCartCustomizations] = useState(false);

  useEffect(() => {
    const populateCart = async () => {
      const hasCustomizedItems = cart.find((item) => item.willCustomize === true);
      setCartCustomizations(hasCustomizedItems);
      const inactiveColRef = await getDocs(collection(db, "STUDY_INACTIVE"));
      const _inactiveStudies = inactiveColRef.docs.map((el) => el.id);
      const cartItems = cart.map((item) => item.study).filter(item => !_inactiveStudies.includes(item.replaceAll(' ', '_').toUpperCase()));
      // setCartStudies(dispatch, new Set(cart.map((item) => item.study)));
      setCartStudies(dispatch, new Set(cartItems));
    }
    populateCart()
  }, [cart]);

  const removeFromCart = (el) => {
    let hardCopy = [...cart];
    hardCopy = hardCopy.filter((cartItem) => cartItem.newID !== el);
    const localStorageCopy = JSON.stringify(hardCopy);
    setCart(dispatch, hardCopy);
    window.localStorage.setItem("cart", localStorageCopy);

    const cartLength = JSON.parse(window.localStorage.getItem("cart")).length;
    if (cartLength === 0) setEmptyCart(dispatch, true);
  };

  const cartItems = Array.from(cartStudies).map((study) => (
    <>
      <MDBox
        py={1.5}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          {study}
        </MDTypography>
      </MDBox>
      {cart
        .filter((item) => item.study === study)
        .map((el) => (
          <CartCard
            key={el.newID}
            id={el.newID}
            image={el.image}
            title={el.title}
            cartQuantity={el.cartQuantity}
            language={el.language}
            willCustomize={el.willCustomize}
            remove={() => {
              removeFromCart(el.newID);
              const variant = "error";
              enqueueSnackbar(`${el.title} - ${el.language} has been removed from the cart.`, {
                variant,
                autoHideDuration: 2000,
              });
            }}
          />
        ))}
    </>
  ));

  const setCartEmpty = () => {
    setCart(dispatch, []);
    window.localStorage.removeItem("cart");
    setEmptyCart(dispatch, true);
    setChosenAddress(dispatch, {
      nickName: "",
      attn: "",
      clinic: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      zip: "",
      country: "United States",
    });
    setFinalAddress(dispatch, {
      attn: "",
      clinic: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      zip: "",
      country: "United States",
    });
    const blankCustomizations = { customized: false, clinicName: "", line2: "", line3: "" };
    setLocalCustomization(dispatch, blankCustomizations);
    window.localStorage.setItem("customization", JSON.stringify(blankCustomizations));
    const variant = "success";
    enqueueSnackbar(`Cart has been emptied.`, {
      variant,
      autoHideDuration: 2000,
    });
  };

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} px={3}>
        <MDBox>
          <MDTypography variant="h5">Cart</MDTypography>
          <MDTypography variant="body2" color="text">
            QD Solutions
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox pb={3} px={3} sx={{ width: "100%" }}>
        {emptyCart && <MDTypography variant="caption">Your cart is empty!</MDTypography>}
        {cartItems}
        <MDBox mt={3} mb={2}>
          <MDButton
            color="text"
            variant="outlined"
            size="small"
            fullWidth
            disabled={emptyCart}
            onClick={setCartEmpty}
          >
            <Icon sx={{ mx: 1 }}>delete</Icon> empty cart
          </MDButton>
          {cartCustomizations && (
            <MDBox p={2}>
              <MDTypography variant="caption" color="success">
                Your cart has customizable items in it.
              </MDTypography>
            </MDBox>
          )}
          {cartCustomizations ? (
            <MDButton
              color="info"
              onClick={() => {
                setPageState(dispatch, "Customization");
                handleCloseConfigurator();
              }}
              fullWidth
            >
              {editOrder ? "edit customization" : "continue to customization"}
            </MDButton>
          ) : (
            <MDBox py={2}>
              <MDButton
                color="info"
                onClick={() => {
                  setPageState(dispatch, "Shipping");
                  handleCloseConfigurator();
                }}
                disabled={emptyCart}
                fullWidth
              >
                continue to shipping
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
